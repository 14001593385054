<template >
    <div class="container-action-button" v-if="params.data">
        <BaseButton @click="onConsultOrder" buttonText="" title="Consulter la commande">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']" />
            </template>
        </BaseButton>
        <BaseButton @click="onEditOrder" buttonText="" title="Éditer la commande" v-if="this.params.data.status === 'ordered'">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'edit']" />
            </template>
        </BaseButton>
        <BaseButton @click="onCopyOrder" buttonText="" title="Dupliquer la commande" :disabled="this.params.data.priceRequestNumber != null">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'copy']" />
            </template>
        </BaseButton>
        <BaseButton @click="onOrderReceipt" buttonText="" title="Réceptionner la commande" :disabled="this.params.data.status === 'completed'">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'box-open']"/>
            </template>
        </BaseButton>
        <BaseButton @click="onDownloadOrder" buttonText="" title="Télécharger le PDF">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-pdf']" />
            </template>
        </BaseButton>
        <BaseButton @click="onDeleteOrder" buttonText="" title="Supprimer la commande" :disabled="this.params.data.status !== 'ordered' || this.params.data.from !== 'order'">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListOrdersActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onConsultOrder() {
                this.params.context.componentParent.displayModalConsultOrder(this.params.data.id);
            },
            onEditOrder() {
                this.params.context.componentParent.displayModalEditOrder(this.params.data.id);
            },
            onCopyOrder() {
                this.params.context.componentParent.displayModalCopyOrder(this.params.data.id);
            },
            onDownloadOrder() {
                this.params.context.componentParent.displayModalAttachmentsOrder(this.params.data.id);
            },
            onOrderReceipt() {
                this.params.context.componentParent.receiptOrder(this.params.data);
            },
            onDeleteOrder() {
                this.params.context.componentParent.deleteOrder(this.params.data.id);
            }
        }
    }        
</script>