<style>
    .icon {
        margin-right: 10px;
    }

    .icon-check {
        color: #4FA601;
    }

    .icon-times {
        color: #78828C;
    }

    .icon-status {
        color: #0D3B70;
    }

    .break-word {
        white-space: normal;
    }
</style>

<template>
    <div v-if="value != null">
        <div v-if="mode === 'boolean'">
            <div v-if="value == 'true' || value === true">
                <font-awesome-icon :icon="['fas', 'check']" class="icon icon-check" /> {{ labelTrue }}
            </div>
            <div v-if="value === 'false' || value === false">
                <font-awesome-icon :icon="['fas', 'times']" class="icon icon-times" /> {{ labelFalse }}
            </div>
        </div>
        <div v-else-if="mode === 'status'" class="break-word">
            <font-awesome-icon :icon="params.context.componentParent.$store.getters.getAffairStatusList[value].icon" class="icon icon-status" /> {{ params.context.componentParent.$store.getters.getAffairStatusList[value].label }}
        </div>
        <div v-else-if="mode === 'customerStatus'" class="break-word">
            <font-awesome-icon :icon="params.context.componentParent.$store.getters.getAffairStatusList[value].customerIcon" class="icon icon-status" /> {{ params.context.componentParent.$store.getters.getAffairStatusList[value].customerLabel }}
        </div>
        <div v-else class="break-word">
            <font-awesome-icon :icon="icon" /> {{ value }}
        </div>
    </div>
</template>

<script>
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListTextWithIconRenderer',
        components: {
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        props: {
            labelTrue: {
                type: String,
                default: 'Oui'
            },
            labelFalse: {
                type: String,
                default: 'Non'
            }
        },
        data() {
            return {
                mode: '',
                value: null,
                icon: ['fas', 'check']
            }
        },
        mounted() {
            this.mode = this.params.mode;
            if (this.params.computedValue != null) {
                this.value = this.params?.computedValue;
            } else {
                this.value = this.params?.data?.[this.params.field];
            }
            if(this.params.icon) {
                this.icon = this.params?.icon;
            }
        }
    }        
</script>