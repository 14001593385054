<style scoped>
    :deep(.article-container) {
        display: flex;
        justify-content: space-between;
        max-width: 15vw;
    }

    #modal-create-copy-consult-request :deep(.modal-content) {
        min-width: 80vw;
    }

    :deep(.modal-container form > div:first-child) {
        flex: 2;
    }

    :deep(.modal-container form > div:last-child) {
        flex: 3;
    }

    :deep(.articles-list .inputeo) {
        margin: 0px 10px;
        width: 100px;
    }

    .article-detail-container {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
    }

    .article-detail-container div:nth-child(1) {
        flex: 2;
    }

    .article-detail-container div:nth-child(2), .article-detail-container div:nth-child(3), .article-detail-container div:nth-child(4) {
        flex: 1;
    }

    .list-articles-container {
        display: flex;
        width: 100%;
    }

    .list-articles-container :deep(.list-with-delete-container:nth-child(2)) {
        margin-top: 18px;
    }

    .list-articles-container :deep(.list-with-delete) {
        max-height: unset;
    }

    #modal-create-copy-consult-request :deep(.list-with-delete) {
        margin-right: 20px;
    }

    #attachment-container {
        margin: 30px 30px;
    }

    .attachment-element {
        margin: 15px 0px;
        color: #1E73BE;
    }

    .attachment-element:hover {
        cursor: pointer;
    }

    .attachment-element > svg {
        margin-right: 10px;
    }

    :deep(.aggrid-filter .multiselect) {
        width: 240px;
    }

    #warning-order-not-validated {
        color: #ff6b00;
        font-weight: 700;
        margin: 20px 20px 0px 20px;;
    }
</style>

<template>
    <ButtonTopbar buttonText="Nouvelle commande" @onTopbarButtonClicked="displayModalCreateOrder">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>
    <Aggrid
        apiRoute="priceRequest/list"
        :apiParams="filters"
        :columnDefs="columnDefs" 
        :context="context"
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        aggridHeightSubstract="100px"
        @rowClicked="((event) => {
            if (event.status  === 'ordered')
              displayModalEditOrder(event.id);
            else
              displayModalConsultOrder(event.id);
        })"
    >
        <template v-slot:filter>
            <div>
                <BaseSelect 
                    v-model="statusSearch"
                    label="Statut"
                    name="statusId"
                    :defaultOptions="orderStatus"
                    @onChange="onFiltersChange($event)"
                />
            </div>
            <div>
                <BaseSelect 
                    v-model="affairSearch"
                    label="Affaire"
                    name="affairId"
                    fieldValue="id"
                    fieldLabel="name"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="affair/search"
                    :minChars="2"
                    :maxHeight="300"
                />
            </div>
            <div>
                <BaseSelect 
                    v-model="supplierSearch"
                    label="Fournisseur"
                    name="supplierId"
                    fieldValue="id"
                    fieldLabel="name"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="supplier/search"
                    :minChars="1"
                    :maxHeight="300"
                />
            </div>
            <div>
                <BaseSelect 
                    v-model="orderSearch"
                    label="Numéro de commande"
                    name="priceRequestId"
                    fieldValue="id"
                    fieldLabel="orderNumber"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="priceRequest/search"
                    :apiParams="{
                        priceRequestMode: 'order'   
                    }"
                    :minChars="2"
                    :maxHeight="300"
                />
            </div>
        </template>
    </Aggrid>
    <Modal id="modal-create-copy-consult-request" v-show="modalCreateOrEditOrCopyOrConsultOrderDisplayed">
        <template v-slot:modalIcon>
            <font-awesome-icon v-if="createOrEditOrCopyOrConsultMode !== 'consult'" :icon="['fas', 'plus']" />
            <font-awesome-icon v-if="createOrEditOrCopyOrConsultMode === 'consult'" :icon="['fas', 'search']" />
        </template>
        <template v-slot:modalTitle>
            <div v-if="createOrEditOrCopyOrConsultMode !== 'consult' && createOrEditOrCopyOrConsultMode !== 'edit'">CRÉER UNE COMMANDE</div>
            <div v-if="createOrEditOrCopyOrConsultMode === 'edit'">ÉDITER UNE COMMANDE</div>
            <div v-if="createOrEditOrCopyOrConsultMode === 'consult'">CONSULTER UNE COMMANDE</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseDatepicker 
                        v-model="order.date"
                        label="Date de la commande"
                        :displayTime="true"
                        name="date"
                        :error="formErrorsModalCreateOrCopyOrConsultOrder.date?.error?.message"
                        @onChange="onFormCreateOrCopyOrConsultOrderInputChange"
                        :required="true"
                        :displayError="displayErrorModalCreateOrCopyOrConsultOrder"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                    />
                    <BaseSelect 
                        v-model="order.affair"
                        label="Affaire"
                        name="affair"
                        api="affair/search"
                        fieldValue="id" 
                        fieldLabel="name"
                        :searchable="true" 
                        :minChars="2"
                        @onChange="onAffairChange"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                    />
                    <BaseSelect 
                        v-model="order.establishment"
                        label="Établissement"
                        :defaultOptions="establishments"
                        name="establishment"
                        :error="formErrorsModalCreateOrCopyOrConsultOrder?.establishment?.error?.message"
                        @onChange="onFormCreateOrCopyOrConsultOrderInputChange"
                        :displayError="displayErrorModalCreateOrCopyOrConsultOrder"
                        :required="true"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult' || order.affair != null"
                    />
                </div>
                <div>
                    <BaseInput
                        v-model="order.shippingCosts"
                        label="Frais de port"
                        type="text"
                        validator="price"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                    />
                    <BaseInput
                        v-model="order.packagingCosts"
                        label="Frais d'emballage"
                        type="text"
                        validator="price"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                    />
                    <BaseInput
                        v-model="order.additionalInfos"
                        label="Champ libre"
                        type="text"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                    />
                </div>
                <div>
                    <BaseSelect 
                        v-model="order.supplier"
                        label="Fournisseur"
                        name="supplier"
                        api="supplier/search"
                        fieldValue="id" 
                        fieldLabel="name"
                        :searchable="true" 
                        :required="true" 
                        :error="formErrorsModalCreateOrCopyOrConsultOrder?.supplier?.error?.message"
                        @onChange="onFormCreateOrCopyOrConsultOrderInputChange"
                        :displayError="displayErrorModalCreateOrCopyOrConsultOrder"
                        :minChars="1"
                        @onButtonActionClick="onAddSupplier"
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                    >
                        <template v-slot:iconAction>
                            <font-awesome-icon :icon="['fas', 'plus']" />
                        </template>
                    </BaseSelect>
                    <BaseSelect 
                        v-model="order.selectedSupplierContact"
                        label="Contact sur bon de commande"
                        name="supplierContact"
                        :defaultOptions="order.contacts"
                        :required="true" 
                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                        :error="formErrorsModalCreateOrCopyOrConsultOrder?.supplierContact?.error?.message"
                        @onChange="onFormCreateOrCopyOrConsultOrderInputChange"
                        :displayError="displayErrorModalCreateOrCopyOrConsultOrder"
                    />
                </div>
                <div>
                    <BaseSelect 
                        v-model="form.articleCurrent"
                        label="Ajouter un article"
                        name="searchArticle"
                        @onChange="onArticleAdd" 
                        api="article/search"
                        :apiParams="{
                            establishment: order.establishment?.value
                        }"
                        fieldValue="id" 
                        fieldLabel="label"
                        :searchable="true"
                        v-if="createOrEditOrCopyOrConsultMode !== 'consult'"
                    />
                </div>
                <div>
                    <div class="list-articles-container">
                        <ListWithDelete class="articles-list" v-model="order.articles1" label="Liste des articles" :disabled="createOrEditOrCopyOrConsultMode === 'consult'">
                            <template v-slot:content="{ item, index }">
                                <div class="article-detail-container">
                                    <div>{{ item.label }}</div>
                                    <BaseInput
                                        v-model="order.articles1[index].quantityOrdered"
                                        type="text"
                                        :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                        :name="'quantityOrdered_1_' + index" 
                                        validator="decimal_2"
                                        :error="formErrorsModalCreateOrCopyOrConsultOrder['quantityOrdered_1_' + index]?.error?.message"
                                        @onChange="onFormCreateOrCopyOrConsultOrderInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateOrCopyOrConsultOrder"
                                        :isSmall="true"
                                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                                    />

                                    <BaseDatepicker 
                                        v-model="order.articles1[index].delayDate"
                                        label="Délai (date)"
                                        :name="'delayDate_1_' + index"
                                        :error="formErrorsModalCreateOrCopyOrConsultOrder['delayDate_1_' + index]?.error?.message"
                                        @onChange="onFormCreateOrCopyOrConsultOrderInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateOrCopyOrConsultOrder"
                                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                                    />
                                    <BaseInput 
                                        v-model="order.articles1[index].buyPrice"
                                        type="text" 
                                        label="Prix achat (HT)"
                                        :name="'buyPrice_1_' + index" 
                                        validator="price"
                                        :error="formErrorsModalCreateOrCopyOrConsultOrder['buyPrice_1_' + index]?.error?.message"
                                        @onChange="onFormCreateOrCopyOrConsultOrderInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateOrCopyOrConsultOrder"
                                        :isSmall="true"
                                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                                    />
                                </div>
                            </template>
                        </ListWithDelete>
                        <ListWithDelete class="articles-list" v-model="order.articles2" v-show="(order.articles2?.length || 0) > 0" :disabled="createOrEditOrCopyOrConsultMode === 'consult'">
                            <template v-slot:content="{ item, index }">
                                <div class="article-detail-container">
                                    <div>{{ item.label }}</div>
                                    <BaseInput
                                        v-model="order.articles2[index].quantityOrdered"
                                        type="text"
                                        :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                        :name="'quantityOrdered_2_' + index" 
                                        validator="decimal_2"
                                        :error="formErrorsModalCreateOrCopyOrConsultOrder['quantityOrdered_2_' + index]?.error?.message"
                                        @onChange="onFormCreateOrCopyOrConsultOrderInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateOrCopyOrConsultOrder"
                                        :isSmall="true"
                                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                                    />
                                    <BaseDatepicker 
                                        v-model="order.articles2[index].delayDate"
                                        label="Délai (date)"
                                        :name="'delayDate_2_' + index"
                                        :error="formErrorsModalCreateOrCopyOrConsultOrder['delayDate_2_' + index]?.error?.message"
                                        @onChange="onFormCreateOrCopyOrConsultOrderInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateOrCopyOrConsultOrder"
                                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                                    />
                                    <BaseInput 
                                        v-model="order.articles2[index].buyPrice"
                                        type="text" 
                                        label="Prix achat (HT)"
                                        validator="price"
                                        :isSmall="true"
                                        :disabled="createOrEditOrCopyOrConsultMode === 'consult'"
                                    />
                                </div>
                            </template>
                        </ListWithDelete>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalCreateOrCopyOrConsultOrder" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModalCreateOrCopyOrConsultOrder" buttonText="Valider" v-if="createOrEditOrCopyOrConsultMode !== 'consult'">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Modal v-show="modalAttachmentsOrderDisplayed" :hideIcon="true" class="auto-height">
        <template v-slot:modalTitle>
            <div>DOCUMENTS</div>
        </template>
        <template v-slot:modalContent>
            <div id="attachment-container">
                <div class="attachment-element" v-for="attachment in attachments" :key="attachment" :data-path="attachment.path" @click="displayDocument">
                    <font-awesome-icon :icon="['fas', 'file-pdf']" />
                    {{ attachment.name }}
                </div>
            </div>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalAttachmentsOrder" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Dialog ref="dialog" />
</template>

<script>
    import Aggrid from '../../components/Aggrid/Aggrid';
    import ListOrdersActionButtonsRenderer from './ListOrdersActionButtonsRenderer.vue';
    import ListTextWithIconRenderer from '../../components/Aggrid/ListTextWithIconRenderer';
    import BaseSelect from '../../components/Base/BaseSelect.vue';
    import BaseInput from '../../components/Base/BaseInput.vue';
    import BaseButton from '../../components/Base/BaseButton.vue';
    import BaseDatepicker from '../../components/Base/BaseDatepicker.vue';
    import Modal from '../../components/App/Modal.vue';
    import Dialog from '../../components/App/Dialog.vue';
    import ListWithDelete from '../../components/App/ListWithDelete'
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar.vue';
    import axios from 'axios';
    import { createToast } from 'mosha-vue-toastify';
    import { format } from 'date-fns';
    import config from '../../config';
    import changeTypeEnum from '../../enums/changeTypeEnum';

    export default {
        name: 'Orders',
        components: {
            Aggrid,
            ListOrdersActionButtonsRenderer,
            ListTextWithIconRenderer,
            BaseSelect,
            BaseInput,
            BaseButton,
            BaseDatepicker,
            Modal,
            Dialog,
            ButtonTopbar,
            ListWithDelete
        },
        data() {
            return {
                columnDefs: [
                    { field: 'orderDate', headerName : `Date de commande`, flex: 1, lockPosition: true, filter: 'agDateColumnFilter', sortable: true },
                    { field: 'orderNumber', headerName : `Numéro de commande`, flex: 1, lockPosition: true, sortable: true },
                    { field: 'affairNumber', headerName : `Affaire(s)`, flex: 1, lockPosition: true, customFilter: 'affair.affairNumber' },
                    { field: 'supplier', headerName : `Fournisseur`, flex: 1, lockPosition: true, customFilter: 'supplier.name' },
                    { headerName : 'Statut', flex: 1, lockPosition: true, cellRendererFunctionName: 'StatutRendererFunction', suppressMenu: true},
                    { field: 'receiptDate', headerName : `Date de réception estimée`, flex: 2, lockPosition: true, filter: 'agDateColumnFilter' },
                    { flex: 2, width: 300, lockPosition: true, cellRenderer: 'ListOrdersActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListOrdersActionButtonsRenderer: ListOrdersActionButtonsRenderer,
                    ListTextWithIconRenderer: ListTextWithIconRenderer,
                },
                cellRendererFunctions: new Map([
                    [
                        'StatutRendererFunction',
                        function(param) {
                            switch (param?.data?.status) {
                                case 'ordered':
                                return 'Commandé'
                                case 'completed':
                                return 'Complète'
                            }
                        }
                    ]
                ]),
                context: null,
                modalCreateOrEditOrCopyOrConsultOrderDisplayed: false,
                modalAttachmentsOrderDisplayed: false,
                order: null,
                attachments: null,
                form: {
                    articleCurrent: null
                },
                displayErrorModalCreateOrCopyOrConsultOrder: false,
                formErrorsModalCreateOrCopyOrConsultOrder: {},
                statusSearch: null,
                affairSearch: null,
                supplierSearch: null,
                orderSearch: null,
                filters: {
                    priceRequestMode: 'order',
                    statusId: null,
                    affairId: null,
                    supplierId: null,
                    priceRequestId: null
                },
                createOrEditOrCopyOrConsultMode: null,
                orderStatus: [
                    {value: 'ordered', label: 'Commandé'},
                    {value: 'completed', label: 'Complète'}
                ],
                establishments: [
                    { label: 'EBC', value: 'ebc' },
                    { label: 'SMG', value: 'smg' }
                ],
                orderSupplierContacts: []
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetModalCreateOrCopyOrConsultOrder();
        },
        methods: {
            deleteOrder(priceRequestId) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir supprimer cette commande ?`
                }).then(() => {
                    axios
                    .put('priceRequest/changeDeleted/' + priceRequestId, {
                        deleted: true
                    }, { 
                        toastSuccessMessage: `Modification effectuée`, 
                        toastError: false, 
                        showPreloader: true 
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload');
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            },
            closeModalCreateOrCopyOrConsultOrder() {
                this.resetModalCreateOrCopyOrConsultOrder();
                this.modalCreateOrEditOrCopyOrConsultOrderDisplayed = false;
                this.displayErrorModalCreateOrCopyOrConsultOrder = false;
            },
            submitModalCreateOrCopyOrConsultOrder() {
                if (this.createOrEditOrCopyOrConsultMode === 'create' || this.createOrEditOrCopyOrConsultMode === 'copy') {
                    this.createOrder();
                } else if (this.createOrEditOrCopyOrConsultMode === 'edit') {
                    this.editOrder();
                }
            },
            displayModalCreateOrder() {
                this.createOrEditOrCopyOrConsultMode = 'create';
                this.resetModalCreateOrCopyOrConsultOrder();
                this.modalCreateOrEditOrCopyOrConsultOrderDisplayed = true;
            },
            displayModalCopyOrder(priceRequestId) {
                axios
                .get(`priceRequest/${priceRequestId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((order) => {
                    this.createOrEditOrCopyOrConsultMode = 'copy';
                    this.prepareCreateOrEditOrCopyOrConsultOrder(order.data);
                    this.modalCreateOrEditOrCopyOrConsultOrderDisplayed = true;
                })
                .catch(() => {});
            },
            displayModalConsultOrder(priceRequestId) {
                axios
                .get(`priceRequest/${priceRequestId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((order) => {
                    this.createOrEditOrCopyOrConsultMode = 'consult';
                    this.prepareCreateOrEditOrCopyOrConsultOrder(order.data);
                    this.modalCreateOrEditOrCopyOrConsultOrderDisplayed = true;
                })
                .catch(() => {});
            },
            displayModalEditOrder(priceRequestId) {
                axios
                    .get(`priceRequest/${priceRequestId}`, {
                        toastError: true,
                        showPreloader: true
                    })
                    .then((order) => {
                        this.createOrEditOrCopyOrConsultMode = 'edit';
                        this.prepareCreateOrEditOrCopyOrConsultOrder(order.data);
                        this.modalCreateOrEditOrCopyOrConsultOrderDisplayed = true
                    })
                    .catch(() => {});
            },
            prepareCreateOrEditOrCopyOrConsultOrder(order) {
                this.order = {
                    id: order.id,
                    date: order.orderDate,
                    supplier: {
                        value: order.supplier.id,
                        label: order.supplier.name
                    },
                };

                this.order.selectedSupplierContactId = order.selectedSupplierContactId;
                this.order.selectedSupplierContact = order.selectedSupplierContact ? {
                        value: order.selectedSupplierContact.id,
                        label: `${order.selectedSupplierContact.firstname} ${order.selectedSupplierContact.lastname} : ${order.selectedSupplierContact.email}`
                } : {};
                this.order.contacts = order.contacts.reduce((orderContacts, contact) => {
                    orderContacts.push({
                        value: contact.id,
                        label: `${contact.firstname} ${contact.lastname} ${contact.email}`
                    });
                    return orderContacts;
                }, []);

                this.order.articles1 = [];
                this.order.articles2 = [];

                for (let index = 0; index < order.articles.length; index++) {
                    let article = order.articles[index];
                    let articleFormatted = {
                        value: article.id,
                        label: article.label,
                        quantityOrdered: article.quantityOrdered,
                        delay: article.delay,
                        delayDate: article.delayDate,
                        buyPrice: article.buyPrice,
                        unit: article.unit
                    };
                    if (index % 2 === 0) {
                        this.order.articles1.push(articleFormatted);
                    } else {
                        this.order.articles2.push(articleFormatted);
                    }
                }

                if (order.affair != null) {
                    this.order.affair = {
                        value: order.affair.id,
                        label: order.affair.number
                    };
                }

                if (order.establishment != null) {
                    this.order.establishment = this.establishments.find(establishment => establishment.value === order.establishment);
                }

                this.order.shippingCosts = order.shippingCosts
                this.order.packagingCosts = order.packagingCosts
                this.order.additionalInfos = order.additionalInfos
            },
            resetModalCreateOrCopyOrConsultOrder() {
                this.order = {
                    date: format(new Date(), 'dd/MM/yyyy HH:mm'),
                    affair: null,
                    supplier: null,
                    contacts: [],
                    selectedSupplierContact: null,
                    articles1: [],
                    articles2: [],
                    shippingCosts: null,
                    packagingCosts: null,
                    additionalInfos: ''
                };
            },
            formateOrder(order) {
                return {
                    id: order.id,
                    date: order.date,
                    affairId: order.affair?.value,
                    supplierId: order.supplier.value,
                    selectedSupplierContactId: order.selectedSupplierContact?.value,
                    articles: order.articles1.concat(order.articles2).map((article) => {
                        return {
                            id: article.value,
                            quantity: parseFloat(article.quantityOrdered),
                            delay: parseInt(article.delay),
                            delayDate: article.delayDate,
                            buyPrice: parseFloat(article.buyPrice)
                        }
                    }),
                    establishment: order.establishment?.value,
                    shippingCosts: parseFloat(order.shippingCosts),
                    packagingCosts: parseFloat(order.packagingCosts),
                    additionalInfos: order.additionalInfos
                };
            },
            createOrder() {
                if (!this.checkFormCreateOrCopyOrConsultOrder()) return;

                axios
                .post('priceRequest/createOrder', this.formateOrder(this.order), { 
                    toastSuccessMessage: `Commande créée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.closeModalCreateOrCopyOrConsultOrder();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            editOrder() {
                if (!this.checkFormCreateOrCopyOrConsultOrder()) return;
                axios
                    .put('priceRequest/updateOrder', this.formateOrder(this.order), {
                        toastSuccessMessage: `Commande modifiée`,
                        toastError: true,
                        showPreloader: true
                    })
                    .then(() => {
                        this.closeModalCreateOrCopyOrConsultOrder();
                        this.emitter.emit('ag-grid-reload');
                    })
                    .catch(() => {});
            },
            onFormCreateOrCopyOrConsultOrderInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrorsModalCreateOrCopyOrConsultOrder[input.name] = input;
                } else {
                    delete this.formErrorsModalCreateOrCopyOrConsultOrder[input.name];
                }
                if(input.name === 'supplier' && input.changeType === 'user'){
                    this.order.selectedSupplierContact = null;
                    this.order.contacts = [];
                    if(input.value !== null){
                        axios.get(`contact/listContacts/supplier?supplierId=${input.value.value}`)
                        .then((result) => {
                            this.order.contacts = result.data.reduce((orderContacts, contact) => {
                                orderContacts.push({
                                    value: contact.id,
                                    label: `${contact.all}`
                                });
                                return orderContacts;
                            }, []);
                        });
                    }
                }

            },
            checkFormCreateOrCopyOrConsultOrder() {
                this.displayErrorModalCreateOrCopyOrConsultOrder = true;

                if (this.order.articles1.length === 0 && this.order.articles2.length === 0) {
                    createToast(`Merci d'ajouter au moins un article`, {
                        position: 'bottom-right',
                        type: 'danger',
                        hideProgressBar: true,
                        showIcon: true,
                        transition: 'slide'
                    });
                    
                    return false;
                }

                return Object.keys(this.formErrorsModalCreateOrCopyOrConsultOrder).length === 0;
            },
            onArticleAdd(article) {
                this.$nextTick(() => {
                    this.form.articleCurrent = null;
                });

                // On ajoute l'article s'il n'est pas déjà présent
                if (article.value != null && this.order.articles1.filter(element => element.value === article.value.value).length === 0 && this.order.articles2.filter(element => element.value === article.value.value).length === 0) {
                    this.isNomenclatureEdited = true;
                    
                    let articleToAdd = {
                        ...article.value,
                        quantityOrdered: 1,
                        delay: 0,
                        delayDate: format(new Date(), 'dd/MM/yyyy HH:mm'),
                        buyPrice: article.value.data.buyPrice,
                        unit: article.value.data.unit
                    };
                    
                    if (this.order.articles1.length <= this.order.articles2.length) {
                        this.order.articles1.push(articleToAdd);
                    } else {
                        this.order.articles2.push(articleToAdd);
                    }
                }
            },
            displayModalAttachmentsOrder(priceRequestId) {
                axios
                .get(`priceRequest/${priceRequestId}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((order) => {
                    let attachments = order.data.documents.map(document => {
                        return {
                            name: document.name,
                            path: `${config.UPLOAD_DIRECTORY}/${document.path}`
                        };
                    });

                    if (order.data.pdfRequest != null) {
                        attachments.push({
                            name: 'Demande de prix',
                            path: `${config.GENERATED_DIRECTORY}/${order.data.pdfRequest}`
                        });
                    }

                    if (order.data.pdfOrder != null) {
                        attachments.push({
                            name: 'Commande',
                            path: `${config.GENERATED_DIRECTORY}/${order.data.pdfOrder}`
                        });
                    }

                    this.attachments = attachments;

                    this.modalAttachmentsOrderDisplayed = true;
                })
                .catch(() => {});
            },
            closeModalAttachmentsOrder() {
                this.modalAttachmentsOrderDisplayed = false;
            },
            displayDocument(event) {
                window.open(`${config.BACK_URL}/${event.currentTarget.dataset.path}`, '_blank').focus();
            },
            onFiltersChange(input) {
                if (input.changeType === changeTypeEnum.USER) {
                    this.filters[input.name] = input?.value?.value || null;
                }
            },
            onAddSupplier() {
                window.open('/fournisseurs?action=addSupplier', '_blank').focus();
            },
            onAffairChange(input) {
                if (input.changeType === changeTypeEnum.USER) {
                    if (this.order.affair == null) {
                        this.order.establishment = null;
                    } else {
                        this.order.establishment = this.establishments.find(establishment => establishment.value === this.order.affair?.data?.establishment);
                    }
                }
            },
            formateReception(reception, priceRequests) {
                let receptionWithPriceRequests = {
                    date: reception.date,
                    supplierId: reception.supplier.value,
                    priceRequests: priceRequests.map((priceRequest) => {
                        return {
                            id: priceRequest.id,
                            articles: priceRequest.articles.map((article) => {
                                return {
                                    id: article.id,
                                    articleKitId: article.articleKitId,
                                    quantityReceived: parseFloat(article.quantityRemaining),
                                    buyPrice: parseFloat(article.buyPrice)
                                };
                            }),
                        };
                    }),
                };

                if (reception.id != null)
                    receptionWithPriceRequests.receptionId = reception.id;

                return receptionWithPriceRequests;
            },
            receiptOrder(order) {
                let reception = {
                    id: order.id,
                    date: format(new Date(), 'dd/MM/yyyy H:mm'),
                    supplier: {
                        label: order.supplier,
                        value: order.supplierId
                    }
                }

                const createReception = () => {
                    axios.get(`priceRequest/listArticlesByPriceRequest?priceRequestIds=${[order.id]}`, { toastError: true, showPreloader: true })
                        .then((result) => {

                            axios.post(`reception/create`,
                                    this.formateReception(
                                        reception,
                                        result.data
                                    ),
                                    {
                                        toastSuccess: "Réception créée",
                                        toastError: true,
                                        showPreloader: true,
                                    }
                                )
                                .then(() => {
                                    this.emitter.emit("ag-grid-reload");
                                })
                                .catch(() => {});

                        }).catch((e) => {
                            console.error(e);
                        });
                }

                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Créer une réception',
                    message: `Voulez-vous indiquer cette commande comme complète et créer une réception ?`
                }).then(() => {
                    createReception()
                })
                .catch(() => {});
            }
        }
    }
</script>